import "./PanelDrawerInscribed.css";
import "animate.css";
import { useAppDispatch } from "../../app/store";
import { setDetailSubjectOffered } from "../../reduxSlices/subjectsSlice";
import { IOfferedSubjectSection } from "../../types";
import ProfessorAndSubjectHours from "../ProfessorAndSubjectHours/ProfessorAndSubjectHours";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";

interface IPropsPanelDrawer {
  subject: IOfferedSubjectSection;
}

const findProfeTitular = (subject: IOfferedSubjectSection) => {
  let profe;
  subject.subjectData.subjectsByType.forEach((cursada) => {
    // @ts-ignore
    if (cursada.subjectInfo.values.rol === "Profesor") {
      // @ts-ignore
      profe = cursada.subjectInfo.values.profesores[0];
    }
  });
  return profe ? profe : "";
};

const convertToObjectRequired = (data: any) => {
  const list = data.subjectsByType.map((item: any) => item.subjectInfo[0]);

  return {
    subjectsByType: [
      {
        idSection: data.subjectsByType[0].idSection,
        idHorario: data.subjectsByType[0].idHorario,
        subjectType: data.subjectsByType[0].subjectType,
        subjectInfo: list,
      },
    ],
    subjectData: data.subjectData,
    reserveData: data.reserveData,
  };
};

const PanelDrawerInscribed = ({ subject }: IPropsPanelDrawer) => {
  const dispatch = useAppDispatch();

  /* -------------------------------------------------------------------------- */
  /*                                  HANDLERS                                  */
  /* -------------------------------------------------------------------------- */

  const handleClose = () => {
    dispatch(setDetailSubjectOffered(null));
  };

  const tagsListingRender = (tags: string) => {
    const colors = ["#C47DDD", "#2277DA", "#209DAE", "#97D86C", "#FBC54F", "#FF7A30", "#FF7A30"];
    const list = tags.split(",").map((item) => item.trim());

    return list.map((tag, i) => <p style={{ background: colors[i < colors.length ? i : colors.length % i] }}>{tag}</p>);
  };

  return (
    <div className="desktop-panel-drawer-inscribed animate__animated animate__slideInRight animate__faster">
      <div className="panel-drawer-inscribed-confirmed">
        <p>Inscripción confirmada</p>
      </div>

      {/* ------------------------------ PANEL CONTENT ----------------------------- */}
      <div className="desktop-panel-drawer-content">
        <div className="panel-drawer-inscribed-description-title">
          <div className="panel-drawer-inscribed-titles">
            <h2 className="panel-drawer-inscribed-title-name">{subject.section}</h2>
            <p className="panel-drawer-inscribed-title-profesor">{findProfeTitular(subject)}</p>
          </div>
          <SlIcon
            name="x-lg"
            style={{ color: "black", height: "20px", cursor: "pointer" }}
            onClick={handleClose}
          ></SlIcon>
        </div>

        <ProfessorAndSubjectHours
          showBorderTop
          showRol
          subjectData={convertToObjectRequired(subject.subjectData)}
          titleClass="panel-drawer-h4-title"
          isDrawerContent={true}
        />

        {(subject.year || subject.creditos || subject.modalidad) && (
          <div className="panel-drawer-content-rows row-modalidad">
            {subject.year && (
              <div className="modalidad-inner-col">
                <h4 className="panel-drawer-h4-title">Año</h4>
                <p>{subject.year}</p>
              </div>
            )}
            {subject.creditos > 0 && (
              <div className="modalidad-inner-col">
                <h4 className="panel-drawer-h4-title">Créditos</h4>
                <p>{subject.creditos}</p>
              </div>
            )}
            {subject.modalidad && (
              <div className="modalidad-inner-col">
                <h4 className="panel-drawer-h4-title">Modalidad</h4>
                <p>{subject.modalidad}</p>
              </div>
            )}
          </div>
        )}

        {subject.tag && (
          <div className="panel-drawer-content-rows">
            <h4 className="panel-drawer-h4-title">Campo menor</h4>
            <div className="panel-tags-list-container">{tagsListingRender(subject.tag)}</div>
          </div>
        )}

        {subject.obs && (
          <div className="panel-drawer-content-rows">
            <h4 className="panel-drawer-h4-title">Observaciones</h4>
            <p>{subject.obs}</p>
          </div>
        )}

        {subject.catalogo && (
          <div className="panel-drawer-content-rows">
            <h4 className="panel-drawer-h4-title">Descripción del curso</h4>
            <p className="panel-drawer-description-box">{subject.catalogo}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PanelDrawerInscribed;
