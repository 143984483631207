import { parseHours, transformHorariosData } from "../../utils";
import { IOfferedSubjectData, IOfferedSubjectSection } from "../../types";
import { selectStudent } from "../../reduxSlices/studentSlice";
import { useSelector } from "react-redux";
import "./ProfessorAndSubjectHours.css";
interface Props {
  subjectData: IOfferedSubjectSection["subjectData"];
  itemClass?: string;
  titleClass?: string;
  showBorderTop?: boolean;
  showRol?: boolean;
  allProfesVisible?: boolean;
  isDrawerContent: boolean;
}

const findProfeTitularForCard = (subjectData: IOfferedSubjectData) => {
  let profe;
  subjectData.subjectsByType.forEach((cursada) => {
    cursada.subjectInfo.forEach((horario) => {
      if (horario.values.rol === "Profesor") {
        profe = horario.values.profesores[0];
      }
    });
  });
  return profe ? profe : "A designar";
};

function ProfessorAndSubjectHours({
  subjectData,
  showBorderTop = false,
  showRol = false,
  itemClass,
  allProfesVisible = true,
  isDrawerContent,
}: Props) {
  const student = useSelector(selectStudent);

  /* ------------------- different horarios in drawer panel ------------------- */
  if (isDrawerContent) {
    return (
      <div className={`content-rows-ph row-ph ${showBorderTop ? "border-top-ph" : ""}`} id="horarios-in-panel-drawer">
        {/* {JSON.stringify(transformHorariosData(subjectData.subjectsByType))} */}

        <div className="col-horarios">
          {subjectData.subjectsByType &&
            transformHorariosData(subjectData.subjectsByType).map((tipo: any, index: number) => {
              const renderProfesor = tipo.titulo === "Profesor";
              const renderHorarios = !renderProfesor;
              const mostrarHorarios = student.mostrarHorarios;              
              if (renderHorarios && mostrarHorarios) {
                return (
                  <div className={`${itemClass ? itemClass : "ph-horarios-inner-col"}`} key={Math.random() + index}>
                    <div className="row-titulos-horario" style={{ display: "flex" }}>
                      <h4 className="panel-drawer-h4-title" style={{ width: "70%", marginRight: "10px" }}>
                        {tipo.titulo}
                      </h4>
                      <h4 className="panel-drawer-h4-title">Aula</h4>
                    </div>
                    {tipo.titulo !== null &&
                      tipo.detalles.map((horario: any, ind: number) => (
                        <div className="row-detalle-horario" style={{ display: "flex" }}>
                          <p key={Math.random() + ind} style={{ width: "70%", marginRight: "10px" }}>
                            {horario?.dia.slice(0, 2)} {parseHours(horario.desde)} a {parseHours(horario.hasta)} h
                          </p>
                          <p>{horario.aula ? horario.aula : "A designar"}</p>
                        </div>
                      ))}
                  </div>
                );
              }
            })}
        </div>

        <div className="col-profesores">
          {subjectData.subjectsByType &&
            transformHorariosData(subjectData.subjectsByType).map((tipo: any, index: number) => {
              const renderProfesor = tipo.titulo === "Profesor";
              if (renderProfesor) {
                return (
                  <div
                    className={`${itemClass ? itemClass : "ph-horarios-inner-col col-profesores-drawer"}`}
                    key={Math.random() + index}
                  >
                    <h4 className={`panel-drawer-h4-title`}>
                      Profesores
                    </h4>
                    { tipo.detalles.map((persona: any, i: number) => (
                          <p key={Math.random() + i}>
                            {persona.nombre.includes("null") ? "A designar" : persona.nombre}
                            {persona.rol && !persona.rol.includes("null") && showRol && (
                              <span className="ph-professor-rol-pill">{persona.rol}</span>
                            )}
                          </p>
                        ))
                     }
                  </div>
                );
              }
            })}
        </div>
      </div>
    );
  }

  /* ---------------------------- content for card ---------------------------- */
  return (
    <div className={`content-rows-ph row-ph ${showBorderTop ? "border-top-ph" : ""}`}>
      {subjectData.subjectsByType &&
        transformHorariosData(subjectData.subjectsByType).map((tipo: any, index: number) => {
          const renderProfesor = tipo.titulo === "Profesor";
          const renderHorarios = !renderProfesor;
          const mostrarHorarios = student.mostrarHorarios;
          if (renderProfesor) {
            return (
              <div className={`ph-horarios-inner-col`} key={Math.random() + index}>
                <h4 className={`ph-h4-title`}>{tipo.titulo === "Profesor" ? "Profesor/a" : tipo.titulo}</h4>
                <p>{findProfeTitularForCard(subjectData)}</p>
              </div>
            );
          } else if (renderHorarios && mostrarHorarios) {
            return (
              <div className={`ph-horarios-inner-col`} key={Math.random() + index}>
                <h4 className={`ph-h4-title`}>{tipo.titulo}</h4>
                {tipo.titulo !== null &&
                  tipo.detalles.map((horario: any, ind: number) => (
                    <p key={Math.random() + ind}>
                      {horario?.dia.slice(0, 2)} {parseHours(horario.desde)} a {parseHours(horario.hasta)} h
                    </p>
                  ))}
              </div>
            );
          }
        })}
    </div>
  );
}

export default ProfessorAndSubjectHours;
