import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import { useEffect, useRef, useState } from "react";
import "./DesktopSelectedSubjects.css";
import Modal from "../Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  selectInscribedSubjects,
  setDetailSubjectInscribed,
  setUnsubProcessSectionIds,
} from "../../reduxSlices/subjectsSlice";
import useUnsubscribeSection from "../../hooks/useUnsubscribeSection";
import { IInscribedSubject } from "../../types";
import SlDivider from "@shoelace-style/shoelace/dist/react/divider";
import EmptyCourses from "../EmptyCourses/EmptyCourses";
import CardInscribedSubject from "../CardInscribedSubject/CardInscribedSubject";

interface ISubjectToUnsub {
  sectionId: number;
  section: string;
}

const DesktopSelectedSubjects = () => {
  const [open, setOpen] = useState(false);
  const [subjectToUnsub, setSubjectToUnsub] = useState<ISubjectToUnsub>();
  const containerRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const inscribedSubjects = useSelector(selectInscribedSubjects);
  const [totalCreditos, setTotalcreditos] = useState(0);

  const unsubscribeSection = useUnsubscribeSection();

  useEffect(() => {
    setTotalcreditos(inscribedSubjects?.reduce((acc, current) => acc + current.sections[0].creditos, 0) || 0);
  }, [inscribedSubjects]);
  /* -------------------------------------------------------------------------- */
  /*                                  HANDLERS                                  */
  /* -------------------------------------------------------------------------- */

  const handleUnsuscribeSection = async (idCurso: number) => {
    const result = await unsubscribeSection(idCurso);
  };

  const handleTrashSubject = async (subject: IInscribedSubject) => {
    setSubjectToUnsub({
      sectionId: subject.idCurso,
      section: subject.title,
    });
  };

  const handleShowInfoPanel = (materiaId: number, idCurso: number) => {
    dispatch(setDetailSubjectInscribed({ subjectId: materiaId, cursoId: idCurso }));
  };

  const renderSelectedSubjects = (subjects: IInscribedSubject[]) => {
    return subjects.map((s, index) => {
      const isLastItem = subjects.length === index + 1;
      return (
        <div className="desktop-item-wrapper" key={s.title + index}>
          <div className="desktop-inscribed-subject-item">
            <div className="desktop-selected-subjects-item-left">
              <h2
                className="desktop-selected-subject-item-left-title"
                onClick={() => handleShowInfoPanel(Number(s.id), s.idCurso)}
              >
                <strong>{s.title.includes("-") ? s.title.split("-")[0] + " - " : s.title}</strong>
                {s.title.includes("-") ? s.title.split("-")[1] : ""}
              </h2>
              <p>{s.sections[0].subjectData.profesor ? s.sections[0].subjectData.profesor : "---"}</p>
              {/* <p>{JSON.stringify(s)}</p> */}
            </div>
            <div className="desktop-selected-subject-item-right">
              <CardInscribedSubject handleTrashSubject={handleTrashSubject} idCurso={s.idCurso} subject={s} />
            </div>
          </div>
          <div className="selected-subject-obs">
            {s.sections[0].obs && (
              <>
                <SlIcon name="exclamation-circle" style={{ paddingRight: "5px", fontSize: "24px" }}></SlIcon>
                <p>{s.sections[0].obs}</p>
              </>
            )}
            {s.sections[0].creditos > 0 && (
              <p className="subject-cant-creditos">
                Créditos: <span>{s.sections[0].creditos}</span>
              </p>
            )}
          </div>
          {!isLastItem && <SlDivider />}
        </div>
      );
    });
  };

  return (
    <div className="desktop-inscribed-subjects" ref={containerRef}>
      <div className="desktop-inscribed-subjects-list">
        {inscribedSubjects && inscribedSubjects?.length > 0 ? (
          <>
            {renderSelectedSubjects(inscribedSubjects)}
            {totalCreditos > 0 && (
              <div className="total-creditos-abogacia">
                <p className="">Total de créditos:</p>
                <h6>{totalCreditos}</h6>
              </div>
            )}
          </>
        ) : (
          <EmptyCourses />
        )}
      </div>
      {subjectToUnsub && subjectToUnsub.sectionId && (
        <Modal
          open={subjectToUnsub.sectionId ? true : false}
          onClose={() => setOpen(false)}
          message={`¿Estás seguro que querés desinscribirte de ${subjectToUnsub.section}?`}
          subMessage="Una vez que te hayas desinscripto perderas tu cupo para cursar dicha materia, esta acción no puede revertirse."
          topBorderWarn={true}
          closeButton={{
            label: "Cancelar",
            buttonFn: () => setSubjectToUnsub(undefined),
            color: "#F5A800",
          }}
          okButton={{
            label: "Si, estoy seguro",
            buttonFn: () => {
              dispatch(setUnsubProcessSectionIds(subjectToUnsub.sectionId));
              handleUnsuscribeSection(subjectToUnsub.sectionId);
              setSubjectToUnsub(undefined);
            },
            color: "#F5A800",
          }}
        />
      )}
    </div>
  );
};

export default DesktopSelectedSubjects;
