import "./PanelDrawer.css";
import "animate.css";
import { useAppDispatch } from "../../app/store";
import {
  addCalendarSubject,
  addCalendarSubjectDemoCount,
  removeCalendarSubject,
  selectCalendarSubjectsList,
  selectPinnedSubjectsList,
  selectSubjectsQuotas,
  setDetailSubjectOffered,
  setLoadingRequestInTransit,
  setPinnedSubjectsList,
  subtractCalendarSubjectDemoCount,
} from "../../reduxSlices/subjectsSlice";
import { ICalendarSubjects, IDesktopCalendarSubjects, IOfferedSubjectSection } from "../../types";
import { FaUser } from "react-icons/fa6";
import { BsFillPinAngleFill } from "react-icons/bs";
import { BsFillCalendarWeekFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { getQuotaColor } from "../../utils";
import { getMateriasFijadasAlumno, toggleMateriaFijadaAlumno } from "../../Api/subject";
import { selectStudent } from "../../reduxSlices/studentSlice";
import { reserveSection } from "../../Api/reservation";
import { selectConfirmationsFlag } from "../../reduxSlices/parametersSlice";
import Modal from "../Modal/Modal";
import { useEffect, useState } from "react";
import { selectInTransitInRquest, setInTransitInscriptionRequest } from "../../reduxSlices/globalFlagsSlice";
import { useToastError } from "../../hooks/useToast";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import SlSpinner from "@shoelace-style/shoelace/dist/react/spinner";
import ProfessorAndSubjectHours from "../ProfessorAndSubjectHours/ProfessorAndSubjectHours";

interface IPropsPanelDrawer {
  subject: IOfferedSubjectSection;
}

const findProfeTitular = (subject: IOfferedSubjectSection) => {
  let profe;
  subject.subjectData.subjectsByType.forEach((cursada) => {
    cursada.subjectInfo.forEach((horario) => {
      if (horario.values.rol === "Profesor") {
        profe = horario.values.profesores[0];
      }
    });
  });
  return profe ? profe : "";
};

const PanelDrawer = ({ subject }: IPropsPanelDrawer) => {
  const dispatch = useAppDispatch();
  const student = useSelector(selectStudent);
  const subjectsQuotas = useSelector(selectSubjectsQuotas);
  const calendarSubsList = useSelector(selectCalendarSubjectsList);
  const pinnedSubjectsList = useSelector(selectPinnedSubjectsList);
  const confirmationsEnabled = useSelector(selectConfirmationsFlag);
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const inTransitInscriptionRequet = useSelector(selectInTransitInRquest);
  const [sendInscription, setSendInscription] = useState(false);
  const [hasClick, setHasClick] = useState(false);
  const { prioridad } = useSelector(selectStudent);

  const showError = useToastError();

  const calendarize = calendarSubsList.some((object: ICalendarSubjects) => object.idCurso === subject.id);
  const pinned = pinnedSubjectsList.indexOf(subject.id) > -1;
  const quota = subjectsQuotas[subject.id];
  const quotaQty = subjectsQuotas[subject.id].qty;

  useEffect(() => {
    if (inTransitInscriptionRequet && hasClick) {
      setSendInscription(true);
      setTimeout(() => {
        setHasClick(false);
        setSendInscription(false);
      }, 10000);
    }
  }, [inTransitInscriptionRequet]);

  /* -------------------------------------------------------------------------- */
  /*                                  HANDLERS                                  */
  /* -------------------------------------------------------------------------- */

  const handleClose = () => {
    dispatch(setDetailSubjectOffered(null));
  };

  const handleToggleCalendarSubject = async (idCurso: number) => {
    const exist = calendarSubsList.some((object: ICalendarSubjects) => object.idCurso === idCurso);
    if (exist) {
      dispatch(removeCalendarSubject(idCurso));
      dispatch(subtractCalendarSubjectDemoCount());

      return;
    }

    let listado: IDesktopCalendarSubjects[] = [];

    dispatch(addCalendarSubjectDemoCount());

    subject.subjectData.subjectsByType.forEach((dia) => {
      dia.subjectInfo.forEach((horario: any) => {
        const newDemo: IDesktopCalendarSubjects = {
          curso: subject.section,
          desde: horario.values.desde,
          hasta: horario.values.hasta,
          dia: horario.values.dia,
          idCurso: idCurso,
          idMateria: Number(subject.idMateria),
          profesor: horario.values.profesores[0],
          warning: false,
          realInscribed: false,
          superpuesta: 0,
          tipoCursada: horario.values.tipoClase,
        };

        listado.push(newDemo);
      });
    });
    listado.forEach((horario) => dispatch(addCalendarSubject(horario)));
  };

  const handlePinSubject = async (idCurso: number, pinned: boolean) => {
    const result = await toggleMateriaFijadaAlumno(student.id, idCurso);
    const fijadas = await getMateriasFijadasAlumno(student.id);
    if (fijadas.status === 200 && fijadas.data !== null) {
      dispatch(setPinnedSubjectsList(fijadas.data));
    } else {
      alert("Error al fijar materia");
    }
  };

  const inscribeToSubject = async () => {
    try {
      setOpenModalConfirmation(false);
      setHasClick(true);
      dispatch(setInTransitInscriptionRequest(true));
      const data = subject.subjectData.reserveData;
      const results = await reserveSection({
        idC: data.idC,
        idS: data.idS,
        idA: data.idA,
        tim: data.tim,
        tic: data.tic,
        idPersona: student.id.toString(),
      });

      if (results.data.responseCode !== 200) {
        // dispatch(removeSelectedOfferedSubject(selectedSection.idS));
        // setReserveError({
        //   ...reserveError,
        //   [selectedSection.idC]: results.data.responseMessage,
        // });
        // showError(results.data.responseMessage, () =>
        //   setReserveError((prevState) => {
        //     const newState = prevState;
        //     delete newState[selectedSection.idC];
        //     return newState;
        //   })
        // );
        showError(results.data.responseMessage);
      } else {
        await dispatch(setLoadingRequestInTransit(true));
      }
    } catch (error) {
      console.log("error :>> ", error);
    } finally {
      dispatch(setInTransitInscriptionRequest(false));
    }
  };

  const handleTryInscribe = async () => {
    if (confirmationsEnabled) {
      setOpenModalConfirmation(true);
    } else {
      inscribeToSubject();
    }
  };

  const tagsListingRender = (tags: string) => {
    const colors = ["#C47DDD", "#2277DA", "#209DAE", "#97D86C", "#FBC54F", "#FF7A30", "#FF7A30"];
    const list = tags.split(",").map((item) => item.trim());

    return list.map((tag, i) => <p style={{ background: colors[i < colors.length ? i : colors.length % i] }}>{tag}</p>);
  };

  return (
    <div>
      <Modal
        open={openModalConfirmation}
        onClose={() => setOpenModalConfirmation(false)}
        message={`¿Desea inscribirse en el curso ${subject.section} ?`}
        okButton={{
          label: "Si",
          buttonFn: () => inscribeToSubject(),
        }}
        closeButton={{
          label: "No",
          buttonFn: () => setOpenModalConfirmation(false),
        }}
      />

      <div className="desktop-panel-drawer animate__animated animate__slideInRight animate__faster">
        {/* ------------------------------ PANEL CONTENT ----------------------------- */}
        <div className="desktop-panel-drawer-content">
          <div className="panel-drawer-description-title">
            {quota && quotaQty >= 0 && prioridad && (
              <>
                <FaUser
                  style={{ color: quota ? getQuotaColor(quota.availability) : "black", margin: "auto 0" }}
                  className="desktop-icon-user-quota"
                />
              </>
            )}
            <div className="panel-drawer-titles">
              <h2 className="panel-drawer-title-name">{subject.section}</h2>
              <p className="panel-drawer-title-profesor">{findProfeTitular(subject)}</p>
            </div>
            <SlIcon
              name="x-lg"
              style={{ color: "black", minWidth: "20px", height: "20px", cursor: "pointer" }}
              onClick={handleClose}
            ></SlIcon>
          </div>

          <ProfessorAndSubjectHours
            showBorderTop
            showRol
            subjectData={subject.subjectData}
            isDrawerContent={true}
          />

          {(subject.year || subject.creditos || subject.modalidad) && (
            <div className="panel-drawer-content-rows row-modalidad">
              {subject.year && (
                <div className="modalidad-inner-col">
                  <h4 className="panel-drawer-h4-title">Año</h4>
                  <p>{subject.year}</p>
                </div>
              )}
              {subject.creditos > 0 && (
                <div className="modalidad-inner-col">
                  <h4 className="panel-drawer-h4-title">Créditos</h4>
                  <p>{subject.creditos}</p>
                </div>
              )}
              {subject.modalidad && (
                <div className="modalidad-inner-col">
                  <h4 className="panel-drawer-h4-title">Modalidad</h4>
                  <p>{subject.modalidad}</p>
                </div>
              )}
            </div>
          )}

          {subject.tag && (
            <div className="panel-drawer-content-rows">
              <h4 className="panel-drawer-h4-title">Campo menor</h4>
              <div className="panel-tags-list-container">{tagsListingRender(subject.tag)}</div>
            </div>
          )}

          {subject.obs && (
            <div className="panel-drawer-content-rows">
              <h4 className="panel-drawer-h4-title">Observaciones</h4>
              <p>{subject.obs}</p>
            </div>
          )}

          {subject.catalogo && (
            <div className="panel-drawer-content-rows">
              <h4 className="panel-drawer-h4-title">Descripción del curso</h4>
              <p className="panel-drawer-description-box">{subject.catalogo}</p>
            </div>
          )}

          <div id="row-actions" className="panel-drawer-content-rows">
            <div className="panel-actions-row">
              <div className="panel-drawer-circle-buttons">
                <button
                  className={`panel-drawer-actions ${pinned ? "btn-pin" : ""}`}
                  title="Fijar materia"
                  onClick={() => handlePinSubject(subject.id, pinned)}
                >
                  <BsFillPinAngleFill />
                </button>
                {student.mostrarHorarios && (
                  <button
                    className={`panel-drawer-actions ${calendarize ? "btn-calendar" : ""}`}
                    title="Visualizar en calendario"
                    onClick={() => handleToggleCalendarSubject(subject.id)}
                  >
                    <BsFillCalendarWeekFill />
                  </button>
                )}
              </div>
              {student.prioridad && quota && quota.availability === 0 ? (
                <button className="desktop-action-add-noquota" disabled>
                  <SlIcon name="x"></SlIcon>
                  Sin cupo
                </button>
              ) : student.prioridad ? (
                <button
                  className={`desktop-action-add ${sendInscription ? "desktop-action-add-disabled" : ""}`}
                  onClick={handleTryInscribe}
                  disabled={inTransitInscriptionRequet}
                >
                  {sendInscription ? (
                    <>
                      <SlSpinner
                        style={{
                          fontSize: ".8rem",
                          //@ts-ignore
                          "--indicator-color": "#00C4B3",
                          "--track-color": "#ccebe8",
                        }}
                      />{" "}
                      Procesando
                    </>
                  ) : (
                    <>
                      <SlIcon name="plus"></SlIcon>Inscribirme
                    </>
                  )}
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PanelDrawer;
